// CSS
import styles from './ListCourses.module.css';

// hooks
import { useNavigate, Link } from 'react-router-dom';
import { useAuthValue } from '../../context/AuthContext';
import { useFetchSavedPath } from '../../hooks/useFetchSavedPath';

// react
import { useState } from 'react';

const ListCourses = () => {
  const { documents: courses, loading } = useFetchSavedPath('courses');
  const { user } = useAuthValue();
  let userId = user.uid;
  const navigate = useNavigate();

  return (
    <div className="container-full homeCourse" id="home-course">
      <div className="container pb-5">
        <div className="row">
          {loading && (
            <div className="container">
              <p>Loading...</p>
            </div>
          )}

          {!loading && courses && (
            <div className="row g-0 justify-content-center">
              <div className="col-md-12">
                <h2 className="mb-lg-3 mb-2 text-start py-4">Courses</h2>
                <div className="row g-5 justify-content-center">
                  {courses.map((course) => (
                    <Link
                      to={`/myhome/${course.code}`}
                      className={`${styles.card_courses} p-3 col-md-4 mx-1`}
                      key={course.id}
                    >
                      <div className="d-flex">
                        <strong className="col-6">
                          <p className="text-blue fs-5 lh-1">{course.name}</p>
                        </strong>
                        <div className="col-6 p-0 m-0 d-flex align-items-start justify-content-end">
                          <img src={course.icon} alt="Course Icon" />
                        </div>
                      </div>

                      <p
                        className={`${styles.textGray} lh-sm mt-lg-4 mt-3 pb-3 ps-1`}
                      >
                        {course.description}
                      </p>
                      <div
                        className="ps-2 mt-2 d-flex align-items-baseline position-absolute"
                        style={{ bottom: '10px' }}
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-level.svg?alt=media&token=f8aa03d9-6a26-402f-9e2b-c9017ecc30bb"
                          className={styles.iconLevel}
                          alt="Course Level Icon"
                        />
                        <small className="text-blue ms-2">{course.level}</small>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListCourses;
